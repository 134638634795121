
.certificate-item{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .select-top{
        display: flex;
        align-items: center;
        margin: 20px 0;
        .select-right{
            margin-left: 60px;
        }

        .class-screening-title{
            margin-right: 10px;
        }
    }
    .no-margin {
        margin: 0 0 20px 0 !important;
    }
}

